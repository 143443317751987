/* Import the Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* Tailwind base styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Reset padding and margins, apply box-sizing */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif; /* Apply Poppins font */
}
